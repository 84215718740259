import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import {
  getZones,
  selectZone,
  unSelectZone,
} from "../../actions/campaign-planning/ZonesAndSubZonesActions";

// Components
import CheckBox from "../../../components/check-box/CheckBox";
import { DataLoading, HeaderWithActionButton } from "./PlanningUtils";

// Zones
function ZoneList({
  zones = [],
  zonesLoading = false,
  selectedRegionId = "",
  selectedRegionName = "",
  selectedRegionAndZonesMap = {},
}) {
  const dispatch = useDispatch();

  // checking zones present
  const isZonesPresent = zones.length > 0;

  // zones for selected region
  const selectedZones = selectedRegionAndZonesMap[selectedRegionId] || [];
  const selectedZoneIds = selectedZones.map((zone) => zone.zoneId);

  // function
  function handleSelectZone(e, zoneId) {
    const { checked } = e.target || {};

    // find selected Zone
    const selectedZone = zones.find((zone) => zone.id === zoneId) || {};

    // construct Zone Object
    const constructedZone = {
      cityId: selectedRegionId,
      cityName: selectedRegionName,
      zoneId: zoneId,
      zoneName: selectedZone?.name || "",
    };

    // dispatch to select/unselect
    const dispatchFn = checked ? selectZone : unSelectZone;
    dispatch(dispatchFn(constructedZone));
  }

  return (
    <div className="col-6 px-0 border-left">
      <h5 className="my-1 px-3 py-2">Zone</h5>
      <hr className="my-0" />

      {/* zones loading */}
      {zonesLoading && (
        <DataLoading
          dataType="Zone"
          className="my-2 px-3 py-2"
          spinnerClassName="spinner-border-sm"
        />
      )}

      {/* no zones */}
      {!zonesLoading && !isZonesPresent && (
        <p className="text-center font-italic text-muted my-2 px-3 py-2">
          No Zone Available
        </p>
      )}

      {/* zones list */}
      {!zonesLoading &&
        isZonesPresent &&
        zones.map((zone) => {
          const { name, id } = zone;
          return (
            <div
              key={id}
              className="d-flex justify-content-between mt-1 px-3 py-2"
            >
              <p className="mb-0" title={name}>
                {name}
              </p>
              <CheckBox
                boxStyle="mb-0"
                checkBoxKey={id}
                showLabel={false}
                checked={selectedZoneIds.includes(id)}
                onChange={handleSelectZone}
              />
            </div>
          );
        })}
    </div>
  );
}

// Regions
function RegionList({
  regionList = [],
  selectedRegionId,
  setSelectedRegionId = () => {},
  setSelectedRegionName = () => {},
  selectedRegionAndZonesMap = {},
}) {
  const dispatch = useDispatch();

  function handleSelectRegion(id, name) {
    setSelectedRegionId(id);
    setSelectedRegionName(name);

    // get zones
    dispatch(getZones(id));
  }

  return (
    <div className="col-6 px-0 border-right">
      <h5 className="my-1 px-3 py-2">City</h5>
      <hr className="my-0" />

      <div className="px-3">
        {regionList.map((region) => {
          const { name, id } = region;
          const isRegionSelected = selectedRegionId === id;
          const selectedBgStyle = isRegionSelected ? "bg-alt rounded-lg" : "";
          const selectedZones = selectedRegionAndZonesMap[id] || [];
          const iconClass = isRegionSelected
            ? "fa-angle-down text-primary"
            : "fa-angle-right";
          return (
            <div
              key={id}
              className={`d-flex align-items-center justify-content-between p-2 cursor-pointer my-2 ${selectedBgStyle}`}
              onClick={() => handleSelectRegion(id, name)}
            >
              <p className="mb-0">{name}</p>
              <p className="mb-0">
                {selectedZones.length > 0 && (
                  <span className="mr-2">({selectedZones.length})</span>
                )}
                <i className={`fa ${iconClass}`} />
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

// Regions and Zones List
function RegionsAndZonesList({
  regionList = [],
  regionListLoading = false,
  zones = [],
  zonesLoading = false,
  selectedRegionAndZonesMap = {},
}) {
  const [selectedRegionId, setSelectedRegionId] = useState("");
  const [selectedRegionName, setSelectedRegionName] = useState("");

  if (regionListLoading) {
    return <DataLoading dataType="City / Zone" />;
  }

  if (regionList.length === 0) {
    return (
      <p className="text-center font-italic text-muted">
        No City / Zone Available
      </p>
    );
  }

  return (
    <div className="w-100 d-flex border rounded-lg overflow-hidden">
      <RegionList
        regionList={regionList}
        selectedRegionId={selectedRegionId}
        setSelectedRegionId={setSelectedRegionId}
        setSelectedRegionName={setSelectedRegionName}
        selectedRegionAndZonesMap={selectedRegionAndZonesMap}
      />
      {selectedRegionId && (
        <ZoneList
          zones={zones}
          zonesLoading={zonesLoading}
          selectedRegionId={selectedRegionId}
          selectedRegionName={selectedRegionName}
          selectedRegionAndZonesMap={selectedRegionAndZonesMap}
        />
      )}
    </div>
  );
}

export default function ZonesTab({
  selectedCityId = "",
  handleTabHeaderBtnClick = () => {},
  tabHeaderBtnLoading = false,
}) {
  // region list
  const regionList = useSelector((state) => state.region.regionList);
  const regionListLoading = useSelector(
    (state) => state.region.regionListLoading
  );

  // Zones
  const zones = useSelector((state) => state.zonesAndSubZones.zones);
  const zonesLoading = useSelector(
    (state) => state.zonesAndSubZones.zonesLoading
  );

  // selected Region & Zones Map
  const selectedRegionAndZonesMap = useSelector(
    (state) => state.zonesAndSubZones.selectedRegionAndZonesMap
  );

  // find selected region (that is city)
  const selectedRegion =
    regionList.find((eachRegion) => eachRegion.id === selectedCityId) || {};

  return (
    <>
      <HeaderWithActionButton
        title="City / Zone"
        buttonText="Confirm"
        onButtonClick={handleTabHeaderBtnClick}
        buttonLoading={tabHeaderBtnLoading}
      />

      {/* Regions and Zones List */}
      <RegionsAndZonesList
        regionList={[selectedRegion]}
        regionListLoading={regionListLoading}
        zones={zones}
        zonesLoading={zonesLoading}
        selectedRegionAndZonesMap={selectedRegionAndZonesMap}
      />
    </>
  );
}
