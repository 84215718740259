import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DivIcon } from "leaflet";
import { Marker, Polyline } from "react-leaflet";

// Actions
import {
  selectMedia,
  unSelectMedia,
} from "../../actions/media-selection/MediaSelectionActions";
import {
  addCpmPriceMediaImpact,
  addFixedPriceMediaImpact,
  removeCpmPriceMediaImpact,
  removeFixedPriceMediaImpact,
} from "../../actions/SubmissionImpactActions";

// Constants and Utils
import { KeysToMediaType, MapZoom } from "../../../constants/GeneralConstants";
import { CPM } from "../../../constants/PriceMode";
import {
  constructCampaignMediaBean,
  getInitialDatesState,
  getInitialPrices,
} from "./MediaContainerUtil";
import { getDifferenceInDaysOfDateObject } from "../../../common-utils/date-utils/DateUtils";

// Components
import LLMap from "../../../components/map/leaflet-map/LLMap";
import TrafficFlowArrow from "../../../components/map/traffic-flow-arrow/TrafficFlowArrow";
import {
  AdditionalButtonState,
  DOOHMediaInfo,
  NonDOOHMediaInfo,
  MediaImage,
  MediaTag,
  BuyerPriceCell,
  MarginValueCell,
  PricingModeCell,
  SellerEarningCell,
} from "./MediaContainerCells";

function MediaDetails({
  roadStretchId,
  media = {},
  cityPlan = {},
  tag,
  openMediaId = "",
}) {
  const dispatch = useDispatch();
  const { campaignId, cityId } = useParams();

  // State to store the priceMode( CPM or FIXED) selected
  const [priceMode, setPriceMode] = useState(CPM);

  // State to store the input buyerPrice
  const [buyerPrice, setBuyerPrice] = useState("");

  // State to store the input sellerPrice
  const [sellerPrice, setSellerPrice] = useState("");

  // media data
  const { mediaGroup = "", mediaId, isCpmEnabled, pricing = {} } = media || {};
  const { price } = pricing || {};
  const IsDOOH = mediaGroup === KeysToMediaType.DOOH;

  // plan data
  const { roadStretchOtsMap = {} } = cityPlan || {};
  const otsSplit = roadStretchOtsMap[roadStretchId];

  const campaignMedia = useSelector(
    (state) => state.mediaSelection.mediaIdToCampaignMedia[mediaId]
  );

  // Media Pricing Info
  const mediaPriceInfo =
    useSelector((state) => state.orgMediaPrice.mediaPriceInfo[mediaId]) || {};

  // State for storing the media available dates
  const [dateObj, setDuration] = useState(
    getInitialDatesState(campaignMedia, cityPlan)
  );
  const duration = getDifferenceInDaysOfDateObject(dateObj);

  const initialPriceDetails = getInitialPrices(
    cityPlan,
    roadStretchId,
    media,
    otsSplit,
    duration,
    mediaPriceInfo
  );

  const isMediaSelected = useSelector(
    (state) => state.mediaSelection.selectedMedia[mediaId]
  );

  // to disable the add and remove media button when the api call is in pending status
  const selectUnSelectMediaLoadingMap = useSelector(
    (state) => state.mediaSelection.selectUnSelectMediaLoading
  );
  const selectUnSelectMediaLoading = selectUnSelectMediaLoadingMap[mediaId];
  const isAnyMediaLoadingIsTrue = Object.keys(
    selectUnSelectMediaLoadingMap
  ).some((mediaId) => selectUnSelectMediaLoadingMap[mediaId] === true);

  // Function which dispatches respective submission impact actions based on priceMode
  function dispatchPriceModeActions(
    isCpmEnabled,
    priceMode,
    selectMedia = true
  ) {
    if (!selectMedia) {
      dispatch(removeCpmPriceMediaImpact(mediaId));
      dispatch(removeFixedPriceMediaImpact(mediaId));
      return;
    }

    if (isCpmEnabled || priceMode === CPM) {
      dispatch(addCpmPriceMediaImpact(mediaId, otsSplit, duration));
      dispatch(removeFixedPriceMediaImpact(mediaId));
      return;
    }

    dispatch(addFixedPriceMediaImpact(mediaId, otsSplit, price, duration));
    dispatch(removeCpmPriceMediaImpact(mediaId));
  }

  // Function which initiates when media is selected
  function addMedia() {
    // dispatchPriceModeActions(isCpmEnabled, priceMode);
    const campaignMediaBean = constructCampaignMediaBean(
      dateObj,
      media,
      priceMode,
      sellerPrice,
      buyerPrice,
      roadStretchId
    );
    dispatch(selectMedia(campaignId, cityId, campaignMediaBean));
  }

  // Function which initiates when media is unSelected
  function removeMedia() {
    // dispatchPriceModeActions(isCpmEnabled, priceMode, false);
    dispatch(unSelectMedia(campaignId, campaignMedia, mediaId));
  }

  // only show opened media
  if (openMediaId !== mediaId) {
    return null;
  }

  return (
    <div className="d-flex align-items-stretch">
      {/* Media Tag and Information */}
      <div className="col-6 px-0 d-flex align-items-center justify-content-center">
        <MediaImage
          media={media}
          addDefaultImageClass={false}
          showNoImageMessage={true}
          iconClassName="fa-5x opacity-25"
          className="border rounded-lg media-selection-image"
        />
      </div>

      <div className="col-6 pr-0">
        <MediaTag media={media} tag={tag} />
        {/* <PricingModeCell
          media={media}
          campaignMedia={campaignMedia}
          setPriceMode={setPriceMode}
          isMediaSelected={isMediaSelected}
          priceMode={priceMode}
        /> */}

        {/* DOOH & Non-DOOH Media Info */}
        {!IsDOOH && (
          <NonDOOHMediaInfo
            media={media}
            duration={dateObj}
            setDuration={setDuration}
            isMediaSelected={isMediaSelected}
            initialPriceDetails={initialPriceDetails}
          />
        )}
        {IsDOOH && <DOOHMediaInfo media={media} duration={dateObj} />}

        <AdditionalButtonState
          isMediaSelected={isMediaSelected}
          selectMedia={addMedia}
          unSelectMedia={removeMedia}
          selectUnSelectMediaLoading={selectUnSelectMediaLoading}
          disabled={isAnyMediaLoadingIsTrue}
        />
      </div>

      {/* TODO:will remove it if not required after api integration */}
      {/* <div>
        <BuyerPriceCell
          buyerPrice={buyerPrice}
          setBuyerPrice={setBuyerPrice}
          campaignMedia={campaignMedia}
          mop={initialPriceDetails.mop}
        />

        <SellerEarningCell
          sellerPrice={sellerPrice}
          setSellerPrice={setSellerPrice}
          media={media}
          campaignMedia={campaignMedia}
          suggestedPrice={initialPriceDetails.suggestedPrice}
        />
        <MarginValueCell
          sellerPrice={sellerPrice}
          buyerPrice={buyerPrice}
          campaignMedia={campaignMedia}
        />

        <AdditionalButtonState
          isMediaSelected={isMediaSelected}
          selectMedia={addMedia}
          unSelectMedia={removeMedia}
          selectUnSelectMediaLoading={selectUnSelectMediaLoading}
        />
      </div> */}
    </div>
  );
}

function StretchLine({ stretchPoints }) {
  const pathOptions = { color: "orange", weight: 10 };
  return (
    <Polyline pathOptions={pathOptions} positions={stretchPoints}></Polyline>
  );
}

function getMediaIconWithTag(
  selectedMediasMap = {},
  mediaId,
  tag,
  roadMedia = []
) {
  const mediaIcons = roadMedia.reduce((acc, eachMedia) => {
    const { id } = eachMedia;
    const isMediaSelected = selectedMediasMap?.[mediaId];
    const clsStr = isMediaSelected ? "bg-success" : "bg-danger";

    acc[id] = new DivIcon({
      className:
        clsStr +
        " media-tag d-flex align-items-center justify-content-center text-white",
      html: tag,
    });
    return acc;
  }, {});

  return mediaIcons[mediaId];
}

/**
 * Media Container
 */
export default function MediaContainer({
  roadInfo = {},
  cityPlan = {},
  roadMedias = [],
}) {
  // State
  const [openMediaId, setOpenMediaId] = useState("");

  // selectedMediaBooleanMap : { mediaId: true, mediaId: false }
  const selectedMediaBooleanMap = useSelector(
    (state) => state.mediaSelection.selectedMedia
  );

  // road media ids
  const firstRoadMedia = roadMedias?.[0] || {};
  const firstRoadMediaId = firstRoadMedia?.mediaId || "";

  // update open media id to open first media by default
  useEffect(() => {
    setOpenMediaId(firstRoadMediaId);
  }, []);

  // if no road medias present
  if (!roadMedias || roadMedias.length < 1) {
    return null;
  }

  // Road Data
  const { trace } = roadInfo || {};
  const center = {
    latitude: trace[0][0],
    longitude: trace[0][1],
  };

  return (
    <div className="d-flex bg-alt">
      <div className="col-4 px-0">
        <LLMap
          center={[center.latitude, center.longitude]}
          zoom={MapZoom.zoomLevel15}
        >
          <StretchLine stretchPoints={trace} />
          <TrafficFlowArrow trace={trace} />

          {roadMedias.map((eachMedia, index) => {
            const { mediaId, latitude, longitude } = eachMedia;
            const mediaTag = index + 1;
            const mediaIcon = getMediaIconWithTag(
              selectedMediaBooleanMap,
              mediaId,
              mediaTag,
              roadMedias
            );

            return (
              <Marker
                key={mediaId}
                icon={mediaIcon}
                position={[latitude, longitude]}
                eventHandlers={{
                  click: () => setOpenMediaId(mediaId),
                }}
              />
            );
          })}
        </LLMap>
      </div>
      <div className="col-8 p-3 cont-media-selection">
        {roadMedias.map((eachMedia, index) => (
          <MediaDetails
            key={eachMedia.id}
            media={eachMedia}
            cityPlan={cityPlan}
            roadStretchId={roadInfo.id}
            tag={index + 1}
            openMediaId={openMediaId}
          />
        ))}
      </div>
    </div>
  );
}
